export const SET_LOADING = "SET_LOADING";
export const SET_FILTER_DATE_RANGE = "SET_FILTER_DATE_RANGE";
export const SET_SELECTED_LOCATION = "SET_SELECTED_LOCATION";
export const SET_SELECTED_CLIENT = "SET_SELECTED_CLIENT";
export const SET_SELECTED_CLIENT_ID = "SET_SELECTED_CLIENT_ID";
export const RESET_DATA="RESET_DATA"
export const SET_SEO_SORT = "SET_SEO_SORT"
export const SET_SEARCH_TERM_SORT = "SET_SEARCH_TERM_SORT"
export const SET_CALL_RAIL_SORT = "SET_CALL_RAIL_SORT"
export const SET_CAMPAIGN_SORT = "SET_CAMPAIGN_SORT"
export const SET_USER_SORT ="SET_USER_SORT"
export const SET_KPI_SORT = "SET_KPI_SORT"
